import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { Dialog } from 'primereact/dialog';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import { NavLink } from 'react-router-dom';
import '../App.css';
import Qservice from '../assets/Qservice.jpg';
import apple from '../assets/apple.png';
import BottomGr from '../assets/bottom-group.png';
import CHOOSE from '../assets/choose.mp4';
import driverChoose from '../assets/driverChoose.mp4';
import ForDriver from '../assets/forDriver.jpg';
import forRider from '../assets/forrider.jpg';
import fPrice from '../assets/fprice.jpg';
import gpy from '../assets/gpay.png';
import grounpImg from '../assets/group.jpg';
import topVideo from '../assets/headerVideo.mp4';
import Lady from '../assets/lady.png';
import maP from '../assets/map.png';
import safety from '../assets/safty.jpg';
import STR3 from '../assets/str3.png';
import STR2 from '../assets/strip2.png';
import STR1 from '../assets/stripe1.png';
import thumb from '../assets/thumb1.png';
import thumb1 from '../assets/thumb2.png';
import Accordion from './Accordion';
function Home() {
    const videoRef = useRef(null);
    useEffect(() => {
        // videoRef.current.play();
    }, [])
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const [data, setdata] = useState({
        name: '',
        mail: '',
        message: ''
    })
    const [error, seterror] = useState('')
    const sendTo = () => {

        for (const key in data) {
            if (data[key] == '') {
                seterror('0')
                console.log('cc');
                return;
            }

        }
        console.log('ok');

        fetch('https://backend.shesharide.co.za/api/contact_web', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Data sent successfully:', data);
                if (data.message == 'success') {
                    fire('success', 'success')
                    handleShowModal()
                }

            })
            .catch(error => {
                console.error('Error sending data:', error);
                fire('Something Went Wrong!!', 'error')
                // Handle error as needed
            });
        setdata({
            name: '',
            mail: '',
            message: ''
        })

    }


    const banner = [
        {
            img: safety,
            head: 'SAFETY FIRST',
            dec: "  Driver & client fingerprint identification ensures peace of mind with every ride."
        },
        {
            img: Qservice,
            head: 'QUALITY SERVICE',
            dec: " From luxury vehicles to compact cars, choose the ride that fits your  needs."
        }, {
            img: fPrice,
            head: 'FIXED PRICING',
            dec: "No surprises, only transparent, consistent pricing. Plan & budget with confidence."
        }, {
            img: grounpImg,
            head: 'INCLUSIVITY',
            dec: "Value for all stakeholders, ensuring equal satisfaction for everyone."
        }
    ]
    const fire = (message, type) => {
        enqueueSnackbar(message, {
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'right ',
                horizontal: 'right'
            }, variant: type
        })
    }

    const LazyComponent = React.lazy(() => import('./LazyLoad'));
    const VideLazy = React.lazy(() => import('./VideoRes'));


    return (
        <div>

            <SnackbarProvider
                autoHideDuration={5000}
            />
            {/* top video section */}
            {/* <section className=' min-h-[auto] md:min-h-[auto] lg:min-h-[auto] flex justify-center items-start relative overflow-x-hidden max-w-[100%] '> */}


            {/* <div class="relative pt-20 z-10 no-scrollbar overflow-hidden flex flex-col justify-between min-h-[60vh] md:min-h-[70vh] lg:min-h-[90vh] overflow-x-hidden max-w-[100%] " > */}
            {/* <Slide direction='down' triggerOnce>
                        <div className=" text-center">
                            <h1 className='text-3xl md:text-6xl lg:text-7xl font-bold text-white'>Revolutionize Your Ride
                                <br />
                                <Fade delay={1000} triggerOnce duration={200} cascade className='text-[#f62f02] pt-2'>

                                    With Shesha!
                                </Fade>
                            </h1>
                        </div>
                    </Slide>
                    <Slide direction='up' triggerOnce className='' delay={1000}>
                        <div className="px-10  lg:px-0 no-scrollbar">
                            <img src={BottomPng} alt="" className="w-full" />
                        </div>
                    </Slide> */}

            {/* </div> */}

            {/* <video autoplay ref={videoRef} muted loop src={topVideo} class=" inset-0   object-cover dim-overlay ">
                </video> */}

            <Suspense fallback={<div className=' animate-pulse' >
                <div class="w-full min-h-[25vh] md:min-h-[50vh] lg:min-h-[80vh] flex justify-center items-center bg-gray-400">
                    <svg class="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                        <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                    </svg>
                </div>
            </div>}>
                <LazyComponent {...{ src: topVideo }} />
            </Suspense>
            {/* </section> */}
            {/* app link section */}
            <section className='text-center bg-[#f7f7f7] py-10 '>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold"> Download this <span className='text-[#f62f02]'>awesome </span> app</h2>
                <p className='uppercase text-[#aeb1b9] pt-4 text-xs md:text-lg lg:text-lg' >available on all modern operation system</p>
                <div className="flex justify-center flex-col md:flex-row lg:flex-row  items-center my-10">
                    <a href='https://apps.apple.com/za/app/shesha-customer/id6648782162' target='_blank' className='decoration-none'>
                        <img src={apple} alt="apple" className='h-[80px] md:h-[100px] lg:h-[auto]' />
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=com.shesha.rider.elite&hl=en&gl=US' target='_blank' className='decoration-none'>
                        <img src={gpy} alt="google" className='h-[80px] md:h-[100px] lg:h-[auto]  ' />
                    </a>

                </div>
            </section>

            {/* text content */}
            <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 px-5 md:px-12 lg:ps-20 items-center gap-5' >
                <Slide direction='left' triggerOnce>
                    <div className="p-2">
                        <h3 className='text-[#f62f02] text-2xl md:text-3xl lg:text-4xl font-bold'>
                            WHO  <span className='text-black'> WE ARE</span>
                        </h3>
                        <div className="text-lg my-5 md:my-8 lg:my-10 flex items-center flex-col justify-center">
                            <p className='' >
                                At Shesha, we don't just offer rides;
                                we craft personalized travel experiences.
                                Our commitment to safety, top-notch
                                service, and budget-friendly rides
                                transforms every journey into a
                                pleasure.
                            </p>
                            <br />
                            <p>
                                Whether you're behind the wheel
                                or in the passenger seat, join the Ride
                                Revolution and choose Shesha for
                                reliable and safe transportation.
                            </p>
                        </div>

                    </div>
                </Slide>
                <Slide direction='right' triggerOnce>
                    <div className="">
                        <img src={Lady} alt="" className='lg:ms-36' />
                    </div>
                </Slide>


            </section>
            {/* choose shesha */}
            <h2 className="text-2xl text-center md:text-3xl mb-10 lg:text-4xl font-bold"> WHY  <span className='text-[#f62f02]'>CHOOSE </span>  SHESHA</h2>
            <section className='min-h-svh  mt-10 flex justify-evenly flex-col items-center px-2 md:px-12 lg:px-16' style={{ background: `url(${STR1}) 100%` }}>
                <div className="flex justify-center flex-col items-center">
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 place-content-center place-items-center gap-12">

                        {banner.map((e, index) => (
                            <Fade direction='up' delay={index * 150} triggerOnce>
                                <div key={index} className="rounded overflow-hidden bg-[#dfdfdf] max-w-[18rem]   shadow-2xl ">
                                    <img src={e.img} alt="" className=" w-[300px] md:h-[300px] h-[150px] lg:h-[300px] overflow-hidden object-cover px-0" />
                                    <div className="py-2 md:p-3 lg:p-4 px-2 md:px-3 lg:px-5 bg-[#dfdfdf]">
                                        <p className=" text-xs md:text-lg lg:text-lg font-bold text-center">{e.head}</p>
                                        <p className="mt-2 text-[12px] md:text-lg lg:text-[1rem]   text-center ">{e.dec}</p>
                                    </div>
                                    <div className="text-center">

                                        <button className="my-3   text-center md:px-16 px-10 lg:px-16 rounded-3xl text-xs md:text-lg lg:text-lg bg-[#f62f02] text-white">NEXT</button>
                                    </div>
                                </div>


                            </Fade>

                        ))}
                    </div>


                </div>

            </section>

            {/* video section */}
            <section className="lg:min-h-[100vh]  md:min-h-[80vh] mt-5 md:mt-0 flex justify-center flex-col " style={{ background: `url(${forRider})`, backgroundSize: 'cover', backgroundPosition: 'center center' }} >
                <h1 className='text-center text-white font-bold md:text-2xl text-lg lg:text-5xl pt-5'> FOR RIDERS</h1>
                <div className="flex justify-center items-center my-16 relative">
                    <Suspense fallback={<div>Loading....</div>}>

                        <VideLazy src={CHOOSE} img={thumb} className='lg:max-h-[80vh]  md:max-h-[50vh] h-[auto] object-cover w-full' />
                    </Suspense>
                </div>
            </section>
            {/* video 2 */}
            <section className='lg:min-h-[110vh]  md:min-h-[80vh] min-h-[50vh] my-5 md:my-0 lg:my-0  flex items-center justify-center relative ' style={{ background: `url(${STR2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: ' center' }}>

                <div className="flex justify-center  lg:mt-[8rem] md:mt-[1rem] mt-0  bg-cover bg-no-repeat flex-col items-center  lg:h-[80vh] w-[100vw] " style={{ background: `url(${ForDriver}) `, backgroundPosition: 'top center ', backgroundSize: 'cover ', backgroundRepeat: 'no-repeat' }}>
                    <h1 className='text-center text-white font-bold md:text-2xl  text-lg lg:text-5xl py-5 '> FOR <span className='text-[#f62f02]'>DRIVERS</span> </h1>
                    <Suspense fallback={<div>Loading....</div>}>
                        <VideLazy img={thumb1} src={driverChoose} className={'lg:h-[450px] md:h-[380] px-10'} />
                    </Suspense>
                    <h1 className='text-center text-white font-bold md:text-2xl  text-lg lg:text-5xl pb-20'> </h1>
                </div >

            </section >
            {/* full video */}
            {/* <section section className=' text-center h-[100%] relative mt-10 md:mt-0 lg:mt-0 ' >
                <VideoRes src={CHOOSE} className='lg:max-h-[80vh]  md:max-h-[50vh] h-[auto] object-cover w-full' />
                <div className="flex  justify-center">

                    <h1 className='bg-black p-2 px-5 absolute -bottom-4 font-bold text-white'><span className='text-[#f62f02] font-bold'>JOIN</span> SHESHA COMMUNITY- <span className='text-[#f62f02] font-bold'>REGISTER NOW</span></h1>
                </div>
            </section > */}
            <Slide direction='up' triggerOnce className=" px-5 md:px-3 lg:px-0  mt-10 bg-white">
                <h1 className='text-center text-[#2a2b2d] font-bold md:text-2xl  text-lg lg:text-5xl py-8 uppercase'> frequently <span className='text-[#f62f02]'>asked questions</span> </h1>
                <div className="box  flex flex-col gap-5 justify-center items-center">
                    <Accordion q={' What is Shesha ?'} answer={`
                         Shesha is an e-hailing service based in Gauteng, offering riders a reliable, safe, and cost-effective transportation solution.`} />
                    <Accordion q={'How is Shesha different from other e-hailing platform ?'} answer={`Shesha offers fixed pricing regardless of time, season, or busy days. Our emphasis on safety, quality, and inclusivity sets us apart in the market.`} />
                    <Accordion q={' In Which areas does Shesha operate ?'} answer={`Currently, we operate in Gauteng, with plans for expansion to other provinces in the near future. Stay tuned for updates!`} />
                    <NavLink to={'/Faq'} className='bg-black p-3 px-5  -bottom-4 font-bold text-[#f62f02]'>READ MORE <span className='text-white font-bold uppercase'>frequently asked questions</span> </NavLink>
                </div>

            </Slide>
            {/* bottom section */}
            <div className='relative lg:min-h-[100vh] md:min-h-[70vh] m-0  flex items-center  justify-center' style={{ background: `url(${STR3}) 100%`, backgroundSize: 'cover', backgroundPosition: ' topcenter  ' }}>
                <Fade triggerOnce>

                    <img src={BottomGr} alt="" className="" />
                </Fade>

            </div>
            {/* contact us */}
            <section className=''>
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2" >
                    <div className="max-h-[680px] overflow-hidden">
                        <img src={maP} alt="" className='w-full object-cover object-top ' />
                    </div>
                    <div className={`bg-custom-color p-8`} >
                        <h1 className='font-bold text-white  lg:text-5xl text-lg  m-10'>CONTACT</h1>
                        <form action="" className="m-10" onFocus={() => seterror('')}>
                            <div className="text-white ">
                                <label htmlFor="" className='block font-bold'>NAME</label>
                                <input type="text" className='w-full max-w-[350px] my-2 h-[35px] focus:outline-none  text-[#f62f02] font-bold text-lg' value={data.name} onChange={(e) => setdata({ ...data, name: e.target.value })} />
                            </div>
                            <div className="text-white ">
                                <label htmlFor="" className='block font-bold'>EMAIL ADDRESS</label>
                                <input type="text" className='w-full max-w-[350px] my-2 h-[35px] focus:outline-none  text-[#f62f02] font-bold text-lg' value={data.mail} onChange={(e) => setdata({ ...data, mail: e.target.value })} />
                            </div>
                            <div className="text-white ">
                                <label htmlFor="" className='block font-bold'>SUBJECT</label>
                                <input type="text" className='text-field w-full max-w-[350px] my-2 h-[35px] focus:outline-none  text-[#f62f02] font-bold text-lg' value={data.message} onChange={(e) => setdata({ ...data, message: e.target.value })} />
                            </div>
                            {
                                error != '' && (
                                    <p className='text-[#f62f02] font-bold text-lg'>All Field is required</p>
                                )
                            }
                            <button onClick={sendTo} className='bg-[#f62f02] py-2 px-5 text-white font-bold mt-2 focus:outline-none' type='button'>Send us the message</button>
                        </form>
                    </div>
                </div>
            </section>
            <Dialog header="Thank you for contacting us" visible={showModal} style={{ width: '50vw' }} onHide={handleCloseModal} draggable={false} dismissableMask>
                <p className="m-0 font-medium">
                    This is an automated response confirming the receipt of your message. Our team will get back to you within 48 hours.
                </p>
            </Dialog>
        </div >
    )
}

export default Home